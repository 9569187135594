<script setup>
import { reactive, ref } from 'vue'
import { saveAs } from 'file-saver';

import * as bootstrap from "bootstrap"


const props = defineProps(['signedIn', 'mapView']);

import {globalBaseUrl, zoomOptions} from "../../javascript/leaflet"

const downloadTerm = reactive({
  name: '',
  content: '',
  version: -1,
  agreed: false
});

const downloadTermDiv = ref(null);
const downloadTermAgreeBtn = ref(null);
const downloadModal = ref(null);

const downloading = ref(null);

function getTerm()
{
  if (downloadTerm.version>0)
    return;
  fetch("/apis/get_term/download.json").then(
      response => {
        return response.json();
      }
  ).then(
      data => {
        downloadTerm.name = data.download.name;
        downloadTerm.content = atob(data.download.content);
        downloadTerm.version = data.download.version;
      }
  );
}

function checkDownloadTermScroll()
{
  if (!downloadTermAgreeBtn.value || !downloadTermAgreeBtn.value.disabled)
    return;
  if (downloadTermDiv.value.scrollHeight - downloadTermDiv.value.scrollTop === downloadTermDiv.value.clientHeight)
  {
    downloadTermAgreeBtn.value.disabled = false;
    downloadTermAgreeBtn.value.classList.remove("disabled");
  }
}

function downloadTiles()
{
  downloading.value = true;
  let northWest = props.mapView.bounds.getNorthWest(),
      southEast = props.mapView.bounds.getSouthEast();
  let filename;
  fetch(
      globalBaseUrl+"/acs?west="+northWest.lng+"&east="+southEast.lng+"&south="+southEast.lat+"&north="+northWest.lat,{
        credentials: "same-origin"
      }).then( res => {
    filename = res.headers.get("Content-Disposition");
    filename = filename.match(/filename="(.+)"/)[1]
    return res.blob()
  }).then( blob => {
    saveAs(blob, filename);
    downloading.value = false;
    let modal = bootstrap.Modal.getOrCreateInstance(downloadModal.value);
    modal.hide();
  });
}

</script>

<template>
  <div id="community-download">
    <div data-placement="top"
       data-bs-content='To download LiveShare data you must <a href="/users/sign_in"><u>log in</u></a> to your ChartSelect account. If you do not have a ChartSelect account you will need to <a href="/users/sign_up"><u>register</u></a>.'
       title="Download Instructions" v-if="!signedIn" class="liveshare-popover" data-bs-toggle="popover">
      <button class="btn btn-std noselect disabled">
        Download LiveShare Maps
      </button>
    </div>
    <button class="btn btn-std"
       data-bs-toggle="modal" data-bs-target="#downloadModal"
       v-on:click="getTerm();"
       v-if="mapView.zoom>=zoomOptions.minZoom && signedIn">
      Download LiveShare Maps
    </button>
    <div class="modal" tabindex="-2" id="downloadModal" ref="downloadModal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" v-if="downloadTerm.version<0">
          <div class="modal-header">
            <h4 class="modal-title">Data License is being updated, please try again later...</h4>
            <a data-bs-dismiss="modal"><span></span></a>
          </div>
        </div>
        <div class="modal-content" v-if="downloadTerm.version>=0" v-on:mouseenter="checkDownloadTermScroll">
          <div class="modal-header">
            <h4 class="modal-title" v-if="!downloadTerm.agreed">{{downloadTerm.name}}</h4>
            <h4 class="modal-title" v-if="downloadTerm.agreed">Download LiveShare Maps</h4>
            <a data-bs-dismiss="modal"><span></span></a>
          </div>
          <div class="fixed-modal-body"
               ref="downloadTermDiv"
               v-on:scroll="checkDownloadTermScroll">
            <div v-html="downloadTerm.content" v-if="!downloadTerm.agreed">
            </div>
            <div v-if="downloadTerm.agreed">
              <div>
                Download a .zip file containing all the LiveShare data showing in the map view. Zoom in or
                out of the map to increase or decreases the size of the downloaded map. After download, copy
                this .zip file on to an <a href="/store/autochart_pc"><u>AutoChart Zero Lines Map
                Card</u></a> and insert the card into a supported Humminbird control head.
              </div>
              <br>
            </div>
          </div>
          <div class="modal-footer" v-if="!downloadTerm.agreed">
            <div class="right">
              <button class="btn btn-std disabled"
                      v-on:click="downloadTerm.agreed=true"
                      disabled="true"
                      ref="downloadTermAgreeBtn"
              >I Agree
              </button>
            </div>
            <button class="btn btn-std right" data-bs-dismiss="modal" aria-label="Close">I Disagree</button>
          </div>
          <div class="modal-footer" v-if="downloadTerm.agreed">
            <div class="right">
              <button class="btn btn-std noselect" v-on:click="downloadTiles"
                      :disabled="downloading">
                {{downloading ? 'Please wait...' : 'Download LiveShare Maps'}}
              </button>
            </div>
          </div>
        </div><!-- /.modal-content -->
        <div v-if="downloading">
          <div style="position:absolute;top:0;width:100%;height:100%;opacity:0.7;background-color:white">
          </div>
          <img style="position:absolute;left:0;right:0;top:0;bottom:0;margin:auto;"
               src="/images/social/HB_ACLS_Processing_White.gif">
        </div>
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
    <div title="Please zoom in further to enable download."
         v-if="(mapView.zoom<zoomOptions.minZoom && signedIn)" class="liveshare-tooltip" tabindex="0">
      <a class="btn btn-std noselect disabled">
        Download LiveShare Maps
      </a>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../assets/stylesheets/shared";
@import "../../assets/stylesheets/legacy";

$breakpoint: 922px;
#community-download {
  font-family: 'Klavika-Regular', Arial, sans-serif;

  & > div > button, & > button {
    display: block;
    width: auto;
    height: 52px;
    padding: 0 23px;
    margin: 0;
    font-weight: normal;
    font-size: 20px;
    @media screen and (max-width: $breakpoint) {
      width: 100%;
    }
  }

  @media screen and (max-width: $breakpoint) {
  //margin-top: 10px;
    width: inherit;
  }
  .modal-dialog {
    .modal-content {
      font-family: 'Klavika-Regular', Arial, sans-serif;

      .modal-header {
        @extend .modal-header-shared;
      }
    }
  }
}
</style>
