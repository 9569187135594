<script setup>
import { reactive, onMounted } from 'vue'
import {userBaseUrl} from "../../javascript/leaflet";

const props = defineProps(['signedIn']);
const emit = defineEmits(['fitbounds'])

const logs = reactive([]);

function getLogs(){
  if (props.signedIn) {
    fetch(userBaseUrl+"/logs.json", {
      headers: {
        'Accept': 'application/json'
      }
    }).then(
        response => {
          if(!response.ok) {
            throw new Error('Could not retrieve user logs.')
          }
          return response.json()
        }
    ).then(data => {
      for (let i = 0; i < data.logs.length; i++) {
        logs.push(data.logs[i])
      }
    })
  }
}

function parseDate(timestamp) {
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

function parseTime(timestamp) {
  const date = new Date(timestamp);
  return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit'})
}

function panMap(north, east, south, west) {
  emit('fitbounds', {north: north, east: east, south: south, west: west})
}

onMounted(() => {
  getLogs();
})

</script>

<template>
  <div id="liveshare-logs">
    <h2>Activity Log</h2>
    <div class="not-signed-in" v-if="!props.signedIn">
      <span>You must be logged in to see your account activity</span>
    </div>
    <div v-if="props.signedIn" style="max-height:332px;">
      <div v-for="log in logs">
        <div v-if="!log.new" class="card rounded-0">
          <div class="card-header rounded-0">
            <h4 class="float-start"><span>{{log.action.toUpperCase()}}: {{parseDate(log.timestamp * 1000).toUpperCase()}}</span></h4>
            <h4 class="float-end"><span>{{parseTime(log.timestamp * 1000)}}</span></h4>
          </div>
          <div class="card-body">
            {{log.message}}
            <br>
            <div v-if="log.successes && log.successes.length > 0">
              Successful uploads:
              <div v-for="success in log.successes">
                <a @click="panMap(success.north, success.east, success.south, success.west)"
                   style="padding-left:3em;cursor:pointer">{{success.file_name}}</a>
              </div>
            </div>
            <div v-if="log.errors && log.errors.length > 0">
              Errors:
              <div v-for="error in log.errors">
                <span style="padding-left:3em">{{error.file_name}}: {{error.error_message}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="log.new" class="card rounded-0">
          <div class="card-header rounded-0">
            <h4 class="float-start"><span>{{log.action.toUpperCase()}}: {{parseDate(log.timestamp * 1000).toUpperCase()}}</span></h4>
          </div>
          <div class="card-body">
            <div v-if="Object.keys(log.successes).length > 0">
              Successful uploads:
              <div v-for="(key,value) in log.successes">
                <a @click="panMap(key[1].bounds.north, key[1].bounds.east, key[1].bounds.south, key[1].bounds.west)"
                   style="padding-left:3em;cursor:pointer">{{key[0]}}</a>
              </div>
            </div>
            <div v-if="Object.keys(log.errors).length > 0">
              Errors:
              <div v-for="(key,value) in log.errors">
                <span style="padding-left:3em">{{value[0]}}: {{value[1]}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../assets/stylesheets/shared";
@import "../../assets/stylesheets/colors";

#liveshare-logs {
  & > div {
    overflow-y: scroll;
    border: 2px solid $corporate-gray-bg;
  }

  & > h2 {
    @extend .flex-box-display;
    align-items: center;
    margin: 0;
    padding-top: 13px;
    padding-bottom: 14px;
  }

  .card-body {
    font-family: 'MyriadPro-Regular', Arial, sans-serif;
  }

  & > .not-signed-in {
    overflow: hidden;
    @extend .flex-box-display;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 18px;
    font-family: 'Klavika-Regular', Arial, sans-serif;
    font-color: $corporate-gray-text;
  }
}
</style>