import "../../javascript/leaflet"
import { createApp } from 'vue'
import App from "../components/liveshare.vue"
import Download from "../components/download.vue"
import Upload from "../components/upload.vue"
import Logs from "../components/logs.vue"
import * as bootstrap from "bootstrap"
const appId = "liveshare-vue"
var app = null;

function loadLiveshare(){
    let element = document.getElementById(appId);
    if (element == null)
    {
        return
    }
    let props = element.getAttribute('props');
    if (props != undefined)
        props = JSON.parse(props)
    else
        props = {}
    app = createApp(App, props)
    app.component('Download', Download)
    app.component('Upload', Upload)
    app.component('Logs', Logs)
    app.mount('#'+appId)

    let popovers = document.querySelector('.liveshare-popover');
    if (popovers)
        new bootstrap.Popover(popovers, {
            container: 'body',
            html: true,
            trigger: 'click'
        })
    let tooltips = document.querySelector('.liveshare-tooltip')
    if (tooltips)
        new bootstrap.Tooltip(tooltips, {
            container: 'body',
            trigger: 'focus'
        })
}

document.addEventListener("turbo:load", function() {
    console.log("turbo:load");
    loadLiveshare()
});

document.addEventListener('turbo:frame-render', function (){
    console.log("turbo:frame-render");
    loadLiveshare()
})