<script setup>
import { reactive, onMounted, ref, watch } from 'vue'
import {
  googleSatellite, googleTerrain, googleRoadmap, osm,
  globalBaseUrl, userBaseUrl, zoomOptions, getGoogleHybrid
} from "../../javascript/leaflet";
import Cookies from 'js-cookie'

import "leaflet.locatecontrol"

import * as bootstrap from "bootstrap"

const props = defineProps(['userMap', 'signedIn']);
const emit = defineEmits(['enableDownload', 'disabledDownload'])

const OpacityMax = 100;
const OpacityMin = 30;
const minLatDifference = 0.001708984375;
const minLngDifference = 0.00663050450385;
const lsBaseUrl = props.userMap ? userBaseUrl : globalBaseUrl;
const globalTileUrl = globalBaseUrl + '/tiles?y={y}&x={x}&z={z}';
const userTileUrl = userBaseUrl + '/tiles?y={y}&x={x}&z={z}';



// var
let map = null;
let layerControl = null;
let scale = null;

// reactive/ref
const rainbowLegend = reactive({imperial:"", metric:""});
const buildTime = ref("");
const nextBuildTime = ref("");
const colorChart = ref("");
const settingActive = ref(false);
const settings = reactive({
  rawData: false,
  shorelineReference: true,
  shorelineReferencePoints: false,
  imperial: true,
  opacity: 80,
  version: 0
});
const acuAndAcdCounter = ref("")
const htCounter = ref("")
const gpxCounter = ref("")

const toastDiv = ref(null);
const toastHeader = ref(null);
const toastSmall = ref(null);
const toastBody = ref(null);

const mapView = reactive({
  zoom: 5,
  center: [37.0902, -95.7129],
  bounds: null
})

// delete
const autoDelZoom = 16;
const manualDelZoom = 18;
const autoDelEnabled = ref(false);
const manualDelEnabled = ref(false);
const delMode = ref('');

// layer
let liveshareGlobal = L.tileLayer(
    globalTileUrl+params(),
    zoomOptions
);
let liveshareUser = L.tileLayer(
    userTileUrl+params(),
    zoomOptions
);

let lsLayer = props.userMap ? liveshareUser : liveshareGlobal;

let shoreline = L.tileLayer(
    globalBaseUrl+'/shorelines?&y={y}&x={x}&z={z}&shoreline_reference_points=true',
    zoomOptions
)

shoreline.setZIndex(10);

let heat = L.heatLayer([],{
  radius: 30, max: 1, maxIntensityZoom: zoomOptions.minZoom-1,
  maxZoom: zoomOptions.minZoom-1,
  minOpacity: 0.5,
  zoomAnimation: true,
  alpha: settings.opacity/100
});

// hack for turbo to work
let googleHybrid = getGoogleHybrid();

// const
const baseMaps = {
  "Google Hybrid": googleHybrid,
  "Google Satellite": googleSatellite,
  "Google Roadmap": googleRoadmap,
  "Google Terrain": googleTerrain,
  "Open Street Map": osm,
};
const overlays = {
  "Liveshare": lsLayer,
  "Heatmap": heat
}

const searchText = ref('')
let geocoder = new google.maps.Geocoder()
let marker = null

function updateMarkers() {
  if (searchText.value.length === 0) {
      if (marker) {
        marker.remove()
      }
    return
  }
  geocoder.geocode({'address': searchText.value}, function (results, status) {
    if (status === 'OK') {
      map.fitBounds([
        [results[0].geometry.viewport.getSouthWest().lat(),
          results[0].geometry.viewport.getSouthWest().lng()],
        [results[0].geometry.viewport.getNorthEast().lat(),
          results[0].geometry.viewport.getNorthEast().lng()]
      ]);
      if (marker) {
        marker.remove()
      }
      marker = L.marker([results[0].geometry.location.lat(),results[0].geometry.location.lng()], {
        title: results[0].formatted_address
      }).addTo(map)
    } else {
      marker.remove()
    }
  })
}

// watch
watch(settings,  async (oldValue, newValue) => {
  // console.log(Object.values(oldValue))
  // console.log(Object.values(newValue))
  updateLayerParams();
  colorChart.value = newValue.imperial ? rainbowLegend.imperial : rainbowLegend.metric;
  if (newValue.shorelineReferencePoints)
    map.addLayer(shoreline);
  else
    map.removeLayer(shoreline);
  lsLayer.setOpacity(settings.opacity/100);
  heat.setOptions({alpha: settings.opacity/100})
  setScaleUnit();
}, { deep: true })

// setting

function setScaleUnit()
{
  if (scale)
    map.removeControl(scale);
  scale = L.control.scale({position: 'topleft', metric: !settings.imperial, imperial: settings.imperial})
  scale.addTo(map)
}

let delMarker = null;
let delCircle = null;
const delBtn = ref(null);
let listener = function (e){
  if (delMarker)
    delMarker.remove();
  if (delCircle)
    delCircle.remove();
  if (delMode.value === 'auto') {
    delCircle = L.circle(e.latlng, {radius: 30, color: '#6aff00', fillOpacity: 0.2}).addTo(map);
    delMarker = L.marker(e.latlng,
        {
          icon: L.icon({
            iconUrl:'/images/deletion/HB_ACLS_Delete_Cursor_Auto_Down.png',
            iconAnchor: [16, 22],
            popupAnchor: [0, -22],
            tooltipAnchor:[0, -22]
          })
        }
    )
  } else {
    delMarker = L.marker(e.latlng,
        {
          icon: L.icon({
            iconUrl:'/images/deletion/HB_ACLS_Delete_Cursor_Manual_Down.png',
            iconAnchor: [16, 22],
            popupAnchor: [0, -22],
            tooltipAnchor:[0, -22]
          })
        }
    )
  }
  delMarker.addTo(map);
  delMarker.bindPopup(
      "<a id='delBtn'>Confirm Delete</a>"
  ).openPopup();
  delBtn.value = document.getElementById("delBtn");
  delBtn.value.addEventListener("click", requestDelete)
}

function requestDelete(){
  let lat = delMarker.getLatLng().lat;
  let lon = delMarker.getLatLng().lng;
  fetch(userBaseUrl+'/data', {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content
    },
    body: JSON.stringify({
      lat: lat,
      lon: lon,
      solid_brush: delMode.value === 'manual',
      timestamp: Date.now()
    })
  }).then(res => res.json()
  ).then(
      res => {
        if (delMarker)
          delMarker.remove();
        if (delCircle)
          delCircle.remove();
        console.log(res);
        let t = bootstrap.Toast.getOrCreateInstance(toastDiv.value);
        if (res.message === "hit"){
          toastHeader.value = "Liveshare Delete";
          toastBody.value = "Successfully removed";
          settings.version = settings.version + 1;
        } else {
          toastHeader.value = "Liveshare Delete";
          toastBody.value = "Nothing to be removed";
        }
        t.show();
      }
  )
}

function toggleDelMode(mode)
{
  let previousMode = delMode.value;
  // disable toggle when out of zoom
  if (!autoDelEnabled.value && mode === 'auto')
    return;
  if (!manualDelEnabled.value && mode === 'manual')
    return;
  // set mode
  if (delMode.value === mode)
    delMode.value = '';
  else
    delMode.value = mode;
  // turn on rawData mode in manual mode
  if (delMode.value === 'manual' && !settings.rawData)
    settings.rawData = true;
  if (delMode.value === 'auto')
    L.DomUtil.addClass(map._container,'auto-cursor-enabled');
  else if (delMode.value === 'manual')
    L.DomUtil.addClass(map._container,'manual-cursor-enabled');

  // toggle listener and cursor
  if (delMode.value !== ''){
    map.on('click', listener);
    // switch between manual/auto
    if (previousMode !== delMode.value)
    {
      if (delMarker)
        delMarker.remove();
      if (delCircle)
        delCircle.remove();
    }
  } else {
    // toggled off
    L.DomUtil.removeClass(map._container,'auto-cursor-enabled');
    L.DomUtil.removeClass(map._container,'manual-cursor-enabled')

    if (previousMode !== '')
      map.off('click', listener);
    if (delMarker)
      delMarker.remove();
    if (delCircle)
      delCircle.remove();
  }
}

function toggleSetting(val)
{
  if (typeof(val) == 'boolean')
    settingActive.value = val;
  else
    settingActive.value = !settingActive.value;
}

function changeOpacity(val)
{
  let newVal = parseInt(settings.opacity) + parseInt(val);
  if (newVal>OpacityMax) {
    newVal = OpacityMax;
  } else if (newVal<OpacityMin) {
    newVal = OpacityMin;
  }
  settings.opacity = newVal;
}

function updateLayerParams()
{
  let baseUrl = props.userMap ? userTileUrl : globalTileUrl;
  lsLayer.setUrl(baseUrl+params())
}

function params()
{
  return "&imperial="+settings.imperial+ "&extra_mode="+(settings.rawData ? '1' : '0')+"&shoreline_reference="+settings.shorelineReference+"&v="+settings.version
}

// map
function initMap()
{
  let mapViewZoom = Cookies.get("mapView.zoom");
  if (mapViewZoom)
    mapView.zoom = mapViewZoom;
  let mapViewLat = Cookies.get("mapView.lat"),
      mapViewLng = Cookies.get("mapView.lng");
  if (mapViewLat && mapViewLng)
    mapView.center = new L.LatLng(mapViewLat, mapViewLng);
  map = L.map('liveshare-global', {
    attributionControl: false,
    center: mapView.center,
    zoom: mapView.zoom,
    minZoom: 4,
    maxZoom: 18,
    layers: [googleHybrid]
  });
  mapView.bounds = map.getBounds();
  if (mapView.zoom >= autoDelZoom )
    autoDelEnabled.value = true;
  if (mapView.zoom >= manualDelZoom)
    manualDelEnabled.value = true;
  map.on('moveend', function() {
    let zoom = map.getZoom();
    // delete
    if (zoom >= autoDelZoom )
      autoDelEnabled.value = true;
    else {
      autoDelEnabled.value = false;
      toggleDelMode('')
    }
    if (zoom >= manualDelZoom)
      manualDelEnabled.value = true;
    else {
      manualDelEnabled.value = false;
      if (delMode.value === 'manual')
        toggleDelMode('');
    }
    // update map view
    mapView.zoom = zoom;
    mapView.bounds = map.getBounds();
    mapView.center = map.getCenter();
    Cookies.set("mapView.zoom", mapView.zoom);
    Cookies.set("mapView.lat", mapView.center.lat);
    Cookies.set("mapView.lng", mapView.center.lng);
  });
  initCtrl();
}

function initCtrl()
{
  layerControl = L.control.layers(baseMaps, overlays, {position: 'topleft'}).addTo(map);
  map.zoomControl.setPosition('bottomright');
  L.control.locate({
    position: 'bottomright',
    returnToPrevBounds: true,
    drawCircle: true,
    drawMarker: true,
    icon: 'leaflet-control-locate-obn',
    iconLoading: 'leaflet-control-locate-loading',
    strings: {
      title: "My Location"
    }
  }).addTo(map);
  setScaleUnit();
  initLayers();
}

function initLayers()
{
  lsLayer.setOpacity(settings.opacity/100);
  heat.setOptions({opacity: settings.opacity/100})
  map.addLayer(lsLayer);
  map.addLayer(heat);
}


// request
function getHeatMap(){
  fetch(lsBaseUrl + "/heatmap.json", {
    headers: {
      'Accept': 'application/json'
    }
  }).then(
      response => {
        if (!response.ok) {
          throw new Error('Could not retrieve heatmap.')
        }
        return response.json()
      }
  ).then(
      data => {
        if (!props.userMap) {
          buildTime.value = data.buildTime;
          nextBuildTime.value =data.nextBuildTime;

        }
        for (const idx in data["heat_points"])
        {
          let pt = [data["heat_points"][idx]["lat"], data["heat_points"][idx]["lng"], 1];
          heat.addLatLng(pt);
        }
      }
  );
}

function getMeta(){
  fetch(lsBaseUrl+"/metadata.json", {
    headers: {
      'Accept': 'application/json'
    }
  }).then(
      response => {
        if (!response.ok) {
          throw new Error('Could not retrieve metadata.')
        }
        return response.json()
      }
  ).then(
      data => {
        rainbowLegend.imperial = "data:image/png;base64,"+data["color_chart"]["imperial"];
        rainbowLegend.metric = "data:image/png;base64,"+data["color_chart"]["metric"];
        if (settings.imperial)
        {
          colorChart.value = rainbowLegend.imperial;
        } else
        {
          colorChart.value = rainbowLegend.metric;
        }
      }
  );
}

function initFileCounts() {
  fetch(lsBaseUrl + "/files/count.json", {
    headers: {
      'Accept': 'application/json'
    }
  }).then(
      response => {
        if (!response.ok) {
          throw new Error('Could not load file counts.')
        }
        return response.json()
      }
  ).then(data => {
    console.log(data)
    acuAndAcdCounter.value = data["acu_count"] + data["acd_count"]
    htCounter.value = data["ht_count"]
    gpxCounter.value = data["gpx_count"]
  })
}

function viewOnMap(args) {
  if (args.north - args.south < minLatDifference && Math.abs(args.east - args.west) < minLngDifference) {
    map.setView(L.latLngBounds([args.south,args.west],[args.north,args.east]).getCenter(), zoomOptions.maxZoom);
  } else {
    map.fitBounds([[args.south, args.west], [args.north, args.east]]);
  }
}

// mounted
onMounted(() => {
  initMap();
  getMeta();
  getHeatMap();
  if (props.userMap) {
    initFileCounts();
  }
  document.querySelector(".leaflet-google-mutant").style.zIndex = 0
})

</script>

<template>
  <div class="display-container">
    <div class="liveshare-display" v-if="!props.userMap">
      <span>Find inland and coastal maps generated by Humminbird community anglers </span>
    </div>
    <div class="mydata-display" v-if="props.userMap">
      <span>DATA TYPE:</span>
      <span><span>ACU/ACD</span><span class="badge bg-warning">{{acuAndAcdCounter}}</span></span>
      <span><span>HT</span><span class="badge bg-warning">{{htCounter}}</span></span>
      <span><span>GPX</span><span class="badge bg-warning">{{gpxCounter}}</span></span>
    </div>
    <form class="settings-container"
          tabindex="0"
          v-bind:class="{active: settingActive}"
          v-on:focusout="toggleSetting(false)"
    >
      <div class="settings-header"
           v-bind:class="{active: settingActive}"
           v-on:click="toggleSetting"
      >
        <div aria-hidden="true" class="settings-icon no-underline"
             v-bind:class="{active: settingActive}" draggable="false"
        >
          <span>Map Settings</span>
        </div>
      </div>
      <div class="settings-drop" v-bind:class="{'d-none': !settingActive}">
        <ul>
          <li v-if="props.userMap">
            <div class="controls checkbox">
              <span>Show Raw Data</span>
              <input id="raw-data" class="check-box" type="checkbox"
                     v-model="settings.rawData">
              <label for="raw-data"></label>
            </div>
          </li>
          <li>
            <div class="controls checkbox">
              <span>Shoreline Reference</span>
              <a href="#shore-ref-modal" data-bs-toggle="modal" class="settings-info"></a>
              <input id="shore-ref" class="check-box" type="checkbox"
                     v-model="settings.shorelineReference">
              <label for="shore-ref"></label>
            </div>
          </li>
          <li>
            <div class="controls checkbox">
              <span>Shoreline Reference Points</span>
              <a href="#shore-ref-pts-modal" data-bs-toggle="modal" class="settings-info"></a>
              <input id="shore-ref-pts" class="check-box" type="checkbox"
                     v-model="settings.shorelineReferencePoints">
              <label for="shore-ref-pts"></label>
            </div>
          </li>
          <li>
            <span>Units of Measure</span>
            <div class="controls radio">
              <div class="controls">
                <span>Feet</span>
                <input id="imperial" type="radio" class="radio" name="distance-unit"
                       v-model="settings.imperial"
                       :value="true"
                >
                <label for="imperial"></label>
              </div>
              <div class="controls">
                <span>Meters</span>
                <input id="metric" type="radio" class="radio" name="distance-unit"
                       v-model="settings.imperial"
                       :value="false"
                >
                <label for="metric"></label>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </form>
    <div class="clearfix"></div>
  </div>

  <div id="liveshare-search" class="noselect">
    <div>Search:</div>
    <input type="text" class="form-control" placeholder="Find a chart"
           autocomplete="off" v-model="searchText" v-on:keyup.enter="updateMarkers()"/>
    <span class="search-submit form-control" aria-hidden="true" @click="updateMarkers()"/>
  </div>
  <div class="alpha-slider-div" draggable="false">
    <div class="decrement" v-on:click="changeOpacity(-10)"></div>
    <input type="range" max="100" min="30" class="range-form"
           v-model.lazy="settings.opacity">
    <div class="increment" v-on:click="changeOpacity(10)"></div>
  </div>
  <div id="liveshare-global" style="height: 500px;" class="map-wrapper">

    <div class="rainbow-frame" v-if="colorChart">
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANYAAAAmCAYAAAC4cWU3AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH4QkMDwg1wzy2WAAAAQhJREFUeNrt21GKwjAQBuDJ4nE8ghfynO29Zl/EtZDQ2p2K634f+GKbvzAwZCI2AgAAAAAAAAAAAAAAgE+UmefMnHJpysxzUd7uLJn1mWp5aC1/MjsX7jfsfNhUlSWzPlMtD69lZubUMjMjIlprjwvi9l3b8bBF3m+yZNZnquVxtXzM/DIMQz2NBRoL/obTlhmyQmWWzPpMtbRjwdvr/ioI7N797Fjw0jPWASMnfO7Y15n2TisLzIcwHvuGO5BREA6gsUBjgcaCf91Y8+0gdv8A23V6Zx69rJWpw2CtobrvYg1fntRYsL2xnLHghWcsoNjonxdzRFyMg7BqfmbHuo4WAIumuvYufAOJ6+WzifuTbgAAAABJRU5ErkJggg=="/>
    </div>
    <div class="rainbow-legend">
      <img v-bind:src="colorChart" alt="Color Chart"/>
    </div>
  </div>
  <div class="deletion-div" v-if="props.userMap">
    <span class="display-deletion-div">Delete Mode:</span>
    <span class="auto-deletion-div"
          v-bind:class="{enabled : autoDelEnabled, active: delMode === 'auto'}"
          v-on:click="toggleDelMode('auto')"
          :disabled="!autoDelEnabled"
          :title="autoDelEnabled ? 'Click to enable' : 'zoom to enable'"
          :style="{cursor: autoDelEnabled ? 'pointer' : 'not-allowed'}"
    >
      Auto
    </span>
    <span class="manual-deletion-div"
          v-bind:class="{enabled : manualDelEnabled, active: delMode === 'manual'}"
          v-on:click="toggleDelMode('manual')"
          :disabled="!manualDelEnabled"
          :title="manualDelEnabled ? 'Click to enable' : 'Zoom to enable'"
          :style="{cursor: manualDelEnabled ? 'pointer' : 'not-allowed'}"
    >
      Manual
    </span>
  </div>

  <div style="text-align: center;">
      <span style="font-size: 12px; padding: 3px; background-color: rgba(211, 211, 211,  0.3) ; border-radius: 3px">
        <a href="https://www.google.com/intl/en-US_US/help/terms_maps/" target="_blank">Google Map</a> |
        <a href="https://leafletjs.com/" target="_blank">Leaflet</a> | <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors
      </span>
  </div>

  <Upload :userMap="props.userMap" :signedIn="props.signedIn"/>

  <Logs v-if="props.userMap" :signedIn="props.signedIn" @fitbounds="viewOnMap"/>
  <div id="community-footer" v-if="!props.userMap">
    <div>
      <div>LiveShare Map Last Generation: {{new Date(buildTime).toString()}}</div>
      <div>LiveShare Map Next Generation (Estimated): {{new Date(nextBuildTime).toString()}}</div>
    </div>

    <Download :signedIn="props.signedIn" :mapView="mapView"/>
  </div>


  <div style="position: fixed; bottom: 0; right: 0; z-index: 3">
    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" ref="toastDiv">
      <div class="toast-header">
        <!--      <img src="..." class="rounded me-2" alt="...">-->
        <strong class="me-auto">{{toastHeader}}</strong>
        <small>{{toastSmall}}</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body" v-html="toastBody">
      </div>
    </div>
  </div>

</template>

<style lang="scss">
@import "../../assets/stylesheets/shared";
@import "../../assets/stylesheets/legacy";
@import "leaflet/dist/leaflet";

#community-footer {
  @extend .flex-box-display;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 9px 0 0 0;
  font-family: 'Klavika-Regular', Arial, sans-serif;
  font-size: 14px;
}

a#delBtn {
  @extend .btn;
  @extend .btn-std;
}

// delete
.auto-cursor-enabled {
  cursor: url('/images/maps/HB_ACLS_Delete_Cursor_Auto_Up.cur'), auto;
}

.manual-cursor-enabled {
  cursor: url('/images/maps/HB_ACLS_Delete_Cursor_Manual_Up.cur'), auto;
}

.leaflet-control-scale-line {
  margin-left: 5px;
}
.leaflet-top, .leaflet-bottom {
  z-index: 2;
}

.deletion-div {
  position: absolute;
  right: 0;
  top: 144px;
  display: flex;

  justify-content: center;
  align-items: center;
  margin: 12px 12px 0;

  & > span {
    line-height: 29px;
    text-align: center;
    cursor: pointer;
    padding: 0 10px;

    &.enabled {
      color: $black;
      cursor: pointer;
    }

    &.active {
      color: $corporate-orange;
      background-color: $corporate-gray-bg;
      text-decoration: underline;
    }
  }

  .display-deletion-div {
    color: white;
    background-color: $corporate-gray-bg;
    @media screen and (max-width: 370px) {
      visibility: hidden;
      display: none;
    }

    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }

  .auto-deletion-div {
    color: #b4b4b4;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;

    @media screen and (max-width: 370px) {
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
    }
  }

  .manual-deletion-div {
    color: #b4b4b4;
    background-color: $white;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
  }

}

.rainbow-frame {
  position: absolute;
  z-index: 1;
  padding: 5px;
  padding-bottom: 6px;
  left: 10px;
  bottom: 20px;
}

.rainbow-legend {
  position: absolute;
  z-index: 2;
  padding: 5px;
  left: 9px;
  bottom: 20px;
}

.alpha-slider-div {
  //@extend .flex-box-display;
  justify-content: center;
  align-items: center;
  transform: rotate(270deg);
  position: absolute;
  left: -69px;
  width: 194px;
  height: 18px;
  z-index: 1;
  top: 380px;

  & > * {
    display: inline-block;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      margin-top: -12px;
    }
    @supports (-ms-accelerator:true) {
      /* IE Edge 12+ CSS styles go here */
      margin-top: -12px;
    }
  }

  div.increment, div.decrement {
    cursor: pointer;
    height: 30px;
    width: 16px;
  }

  div.increment {
    background: transparent url('/images/liveshare/icons/HB_ACLS_Transparency_100.png') no-repeat center;
  }

  div.decrement {
    background: transparent url('/images/liveshare/icons/HB_ACLS_Transparency_0.png') no-repeat center;
  }

  input[type=range] {
    -webkit-appearance: none;
    background: transparent;
    width: 150px;
    margin: 0 6px;
    height: 30px;
    //padding-bottom: 6.6px;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 148px;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: $white;
    border-radius: 0;
    border: 1px solid $black;
  }

  input[type=range]::-webkit-slider-thumb {
    box-shadow: none;
    border: 1px solid $black;
    height: 18px;
    width: 10px;
    border-radius: 0;
    background: $white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: $white;
  }

  input[type=range]::-moz-range-track {
    width: 146px;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: $white;
    border-radius: 0;
    border: 1px solid $black;
  }

  input[type=range]::-moz-range-thumb {
    box-shadow: none;
    border: 1px solid #000000;
    height: 16px;
    width: 8px;
    border-radius: 0;
    background: $white;
    cursor: pointer;
  }

  input[type=range]::-ms-track {
    width: 150px;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 8px 0;
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: $white;
    border: 1px solid $black;
    border-radius: 0;
    box-shadow: none;
  }

  input[type=range]::-ms-fill-upper {
    background: $white;
    border: 1px solid $black;
    border-radius: 0;
    box-shadow: none;
  }

  input[type=range]::-ms-thumb {
    box-shadow: none;
    border: 1px solid $black;
    height: 16px;
    width: 8px;
    border-radius: 0;
    background: $white;
    cursor: pointer;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: $white;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: $white;
  }

  input[type=range]::-ms-tooltip {
    display: none;
  }
}

.liveshare-toast {

}

.leaflet-control-locate-obn, .leaflet-control-locate-loading {
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: 100%;
}

.leaflet-control-locate-obn {
  background-image: url("../images/liveshare/HB_ACLS_Location_Icon.png");
}
.leaflet-control-locate-loading {
  background-image: url("../images/liveshare/HB_ACLS_Location_Icon.png");
}

.map-wrapper {
  border-left: 3px solid #595959;
  border-right: 3px solid #595959;
  border-bottom: 3px solid #595959;
  width: 100%;
  position: relative;
  .button-wrapper-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 500;
  }
}

.leaflet-pane {
  z-index: 0;
}

</style>
