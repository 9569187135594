<script setup>
import { reactive, ref } from 'vue'
import {userBaseUrl} from "../../javascript/leaflet";

const props = defineProps(['userMap', 'signedIn']);

const uploadTerm = reactive({
  name: '',
  content: '',
  version: -1,
  agreed: false
});
const filesToUpload = reactive([]);

const uploadTermDiv = ref(null);
const uploadTermAgreeBtn = ref(null);
const uploadInput = ref(null);


function getUploadTerm()
{
  if (uploadTerm.version>0)
    return;
  fetch("/apis/get_term/upload.json").then(
      response => {
        return response.json();
      }
  ).then(
      data => {
        uploadTerm.name = data.upload.name;
        uploadTerm.content = atob(data.upload.content);
        uploadTerm.version = data.upload.version;
      }
  );
}

function checkUploadTermScroll()
{
  if (!uploadTermAgreeBtn.value || !uploadTermAgreeBtn.value.disabled)
    return;
  if (uploadTermDiv.value.scrollHeight - uploadTermDiv.value.scrollTop === uploadTermDiv.value.clientHeight)
  {
    uploadTermAgreeBtn.value.disabled = false;
    uploadTermAgreeBtn.value.classList.remove("disabled");
  }
}

function uploadFile(index)
{
  let file = filesToUpload[index];
  if (file.status !== 'pending')
  {
    if (index+1<filesToUpload.length)
    {
      uploadFile(index+1);
      return;
    }
  }
  filesToUpload[index].status = 'uploading';
  let formData = new FormData();
  formData.append('file', uploadInput.value.files[index]);
  fetch(userBaseUrl+'/data', { // Your POST endpoint
    method: 'POST',
    credentials: "same-origin",
    headers: {
      // Content-Type may need to be completely **omitted**
      // or you may need something
      "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content
    },
    body: formData // This is your file object
  }).then(
      response => {
        return response.json();
      } // if the response is a JSON object
  ).then(
      success => {
        if (success.status)
          filesToUpload[index].status = 'uploaded';
        else
          filesToUpload[index].status = 'failed';
        if (index+1<filesToUpload.length)
          uploadFile(index+1)
      } // Handle the success response object
  ).catch(
      error => console.log(error) // Handle the error response object
  );
}

function testUpload(sha2, index)
{
  let f = filesToUpload[index];
  fetch(userBaseUrl+"/test_upload/"+sha2+"?filename="+f.name).then(
      response => {
        let status = response.status;
        f.sha2 = sha2;
        if (status === 200)
          f.status = 'pending';
        else if (status === 201)
          f.status = 'uploaded';
        else if (status === 409)
          f.status = 'duplicated';
        else
          f.status = 'failed';
        if (index === filesToUpload.length-1)
        {
          uploadFile(0);
        }
      }
  )
}

function uploadInputChanged()
{
  filesToUpload.length = 0;
  let size =  uploadInput.value.files.length;
  for (let i = 0; i < size; i++) {
    let file = uploadInput.value.files[i];
    let f = {
      sha2: null,
      name: file.name,
      size: file.size,
      status: 'checking'
    }
    filesToUpload.push(f);
    let reader = new FileReader();
    reader.onload = function(evt) {
      let bytes = evt.target.result;
      crypto.subtle.digest('SHA-256', bytes).then(
          hashBuffer => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));// convert buffer to byte array
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert
            testUpload(hashHex, i);
          }
      );
    };
    reader.readAsArrayBuffer(file);
  }
}

</script>

<template>
  <div id="my-data-upload" class="float-end">
    <button v-if="props.userMap" id="track-upload" class="btn btn-std noselect track-upload"
       v-on:click="getUploadTerm"
       data-bs-toggle="modal" data-bs-target="#uploadTerm" >
      Share My Data
    </button>
    <div v-if="props.signedIn" class="modal" tabindex="-1" role="dialog" id="uploadTerm" v-on:mouseenter="checkUploadTermScroll">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" v-if="uploadTerm.version<0">
          <div class="modal-header">
            <h4>Data License is being updated, please try again later...</h4>
            <a data-bs-dismiss="modal"><span></span></a>
          </div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" v-if="!uploadTerm.agreed">{{uploadTerm.name}}</h4>
            <h4 class="modal-title" v-if="uploadTerm.agreed">Data Upload</h4>
            <a data-bs-dismiss="modal"><span></span></a>
          </div>
          <div class="fixed-modal-body"
               id="upload-modal-body"
               ref="uploadTermDiv"
               v-on:scroll="checkUploadTermScroll">
            <div v-if="!uploadTerm.agreed"
                 v-html="uploadTerm.content"
            >
            </div>
            <div v-if="uploadTerm.agreed && filesToUpload.length >0" >
              <table class="table table-striped">
                <thead>
                <tr class="table-dark">
                  <th scope="col">Filename</th>
                  <th scope="col">Size</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="file in filesToUpload">
                  <td style="max-width: 200px; word-wrap: break-word">
                    <span>
                      {{ file.name }}
                    </span>
                  </td>
                  <td>
                    {{file.size}}
                  </td>
                  <td>
                    <span class="badge rounded-pill"
                          v-bind:class="{
                            'bg-info': file.status === 'checking',
                            'bg-secondary': file.status === 'pending',
                            'bg-warning': file.status === 'duplicated',
                            'bg-primary': file.status === 'uploading',
                            'bg-success': file.status === 'uploaded',
                            'bg-danger': file.status === 'failed'
                          }">
                      <i class="bi bi-cloud-arrow-up-fill" ></i>
                    </span>
                    {{file.status}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div style="padding: 18px; border: 2px dashed grey; border-radius: 10px;"  v-if="uploadTerm.agreed && filesToUpload.length === 0">
              <p><b>How to:</b></p>
              <div class="tab-spacing">
                <p>-  Click the <b>Share My Data</b> button and select one or more file(s) to upload.</p>
                <p>-  Or you can click and drag files from your file browser into this dotted box.</p>
              </div>
              <p><b>Compatible files:</b></p>
              <div class="tab-spacing">
                <p>Humminbird AutoChart Live – (.ACU)</p>
                <p>AutoChart PC Sonar Format – (.ACD)</p>
                <p>Humminbird Track Format for HELIX and older Humminbird models – (.HT)</p>
                <p>Humminbird Track Format for SOLIX models and ONIX models – (.GPX)</p>
              </div>
              <p><b>Upload Capacities:</b></p>
              <div class="tab-spacing">
                <p>The maximum number of files allowed per upload is 500.</p>
                <p>The maximum amount of data allowed per upload is 750MB.</p>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="!uploadTerm.agreed">
            <div class="right liveshare-tooltip" data-bs-toggle="tooltip" title="Please scroll to bottom to Agree">
              <button class="btn btn-std disabled"
                      disabled
                      id="agree-upload" style="font-weight:normal;margin-left:5px;"
                      ref="uploadTermAgreeBtn"
                      v-on:click="uploadTerm.agreed = true"
              >I Agree
              </button>
            </div>
            <button class="btn btn-std right" style="font-weight:normal;" data-bs-dismiss="modal" aria-label="Close">I Disagree</button>
          </div>
          <div class="modal-footer" v-if="uploadTerm.agreed">
            <label id="track-upload"
                   class="btn btn-std track-upload liveshare-tooltip"
                   data-bs-toggle="tooltip"
                   title="Click and select one or more file(s) to upload. Or you can drag and drop them here."
                   for="uploadInput"
            >
              Share My Data
            </label>
            <input type="file" name="uploads" multiple class="d-none" id="uploadInput"
                   accept=".acu,.acd,.ht,.gpx"
                   ref="uploadInput" v-on:change="uploadInputChanged"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../assets/stylesheets/shared";
@import "../../assets/stylesheets/legacy";

#my-data-upload {
  font-family: 'Klavika-Regular', Arial, sans-serif;
  margin: 4px 0;
  padding: 0;

  @include media-breakpoint-down(lg) {
    float: none;
  }

  & > button, & > div > button {
    display: block;
    height: 52px;
    width: auto;
    font-size: 20px;
    padding: 0 23px;
    margin: 0;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  button, label {
    @extend .btn-std;
    font-weight: normal;
    text-transform: none;
  }

  .modal-dialog {
    .modal-content {
      font-family: 'Klavika-Regular', Arial, sans-serif;

      .modal-header {
        @extend .modal-header-shared;
      }

      .modal-body {

      }

      .modal-footer {
        font-family: 'Klavika-Medium', Arial, sans-serif;
      }
    }
  }
}

#upload-modal-body {
  .tab-spacing {
    & > p {
      padding-left: 1.5em;
    }
  }
}
</style>